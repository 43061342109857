import { LocalBusinessJsonLd, LocalBusinessJsonLdProps } from 'next-seo'
import { FunctionComponent } from 'react'
import { REVIEW_RATING } from 'lib/constants'

type Review = {
  author?: string
  datePublished?: string
  body?: string
  rating?: string
}

interface Address {
  streetAddress: string
  addressLocality: string
  addressRegion?: string
  postalCode: string
  addressCountry: string
}

interface Props extends Partial<LocalBusinessJsonLdProps> {
  type?: string
  id?: string
  name?: string
  address?: Address
  review?: Review[]
  keyOverride?: string
}

const setReviews = (reviewData: Review[]) => {
  if (reviewData?.length) {
    return reviewData.map((review) => ({
      author: review?.author || '',
      datePublished: review?.datePublished || '',
      reviewBody: review?.body || '',
      reviewRating: {
        ratingValue: review?.rating || REVIEW_RATING?.toString(),
      },
    }))
  }
  return undefined
}

const LocalBusiness: FunctionComponent<Props> = ({
  type = 'Dentist',
  id = `https://www.hellotend.com/`,
  name = 'TEND',
  description = null,
  address = null,
  review,
  ...rest
}) => {
  const data = {
    ...rest,
    type: type,
    id: id,
    name: name,
    address: address,
    description: description,
    review: setReviews(review),
  }

  return <LocalBusinessJsonLd {...data} />
}

export default LocalBusiness
